// stylelint-disable selector-no-qualifying-type
//
// Base styles
//
.btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }
    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }
    // Opinionated: add "hand" cursor to non-disabled .btn elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);
        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }
    // No need for an active state here
}

.btn-white-border {
    font-weight: $font-weight-normal;
    color: $link-color;
    color: #7F8FA4;
    background-color: $white;
    border: 1px solid #E4E0E0;
    @include flexbox;
    @include align-items(center);
    @include hover {
        color: #7F8FA4;
        text-decoration: none;
        background-color: $gray-400;
        border-color: #E4E0E0;
    }
    &:focus,
    &.focus {
        color: #7F8FA4;
        text-decoration: none;
        background-color: $gray-400;
        border-color: #E4E0E0;
    }
    &:disabled,
    &.disabled {
        opacity: 0.7;
        pointer-events: none;
    }
    // No need for an active state here
}

//
// Button Sizes
//
.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

//
// Block button
//
.btn-block {
    display: block;
    width: 100%;
    // Vertically space out multiple block buttons
    +.btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

.btn {
    &.center-btn-with-icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
        .right-icon {
            margin: 0 0 0 5px;
        }
    }
}

button.mat-stroked-button.mat-primary {
    background: $white;
    height: 1.857rem;
    border-color: $blue;
    line-height: 1.857rem;
    font-size: 0.857rem;
}

button.btn.btn-primary-border {
    background: $white;
    border: 1px solid $blue;
    border-radius: 3px;
    color: $blue;
}

button.btn.btn-blue {
    background: $dark-blue;
    border: 1px solid $dark-blue;
    border-radius: 3px;
    color: $white;
    height: 3rem;
    padding: 0 1rem;
    display: inline-flex;
    align-items: center;
    font-weight: 300;
    font-size: 1rem;
    &:hover {
        background: $blue;
        border: 1px solid $blue;
    }
    .arrow-right {
        margin: 0 0 0 3rem;
        font-size: 1.4rem;
    }
}
.cm-md-modal {
    overflow: auto;
    .modal-inner {
        position: relative;
        .modal-header {
            position: relative;
            padding: 1.8rem 3rem;
            &.style-2 {
                border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1rem;
                margin-bottom: 1.5rem;
            }
            h3 {
                font-size: 1.8rem;
            }
            .close-btn {
                position: absolute;
                right: 0.5rem;
                top: 1rem;
                border: 0;
                background: none;
                cursor: pointer;
                z-index: 1;
                i {
                    font-size: 2rem;
                }
            }
            p {}
        }
        .modal-body {
            position: relative;
            padding: 0rem 3rem;
        }
        .modal-footer {
            padding: 1.5rem 3rem;
            position: relative;
            &.style-2 {
                @include flexbox;
                .btn {
                    margin: 0;
                    width: auto;
                }
                .btn-link {
                    color: #888888;
                }
            }
            .bottom-text {
                margin: 1.5rem 0;
            }
        }
    }
}
/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */

@import url('https://rsms.me/inter/inter-ui.css');
@import "assets/scss/functions";
@import "assets/scss/variables";
@import "assets/scss/mixins";
@import "assets/scss/reboot";
@import "assets/scss/grid"; // add the grid
@import "assets/scss/reset";
@import "assets/scss/utilities";
@import "assets/scss/buttons";
@import "assets/scss/modal";
@import "assets/scss/button-group";
@import "assets/scss/material_base";
@import "assets/scss/custom";
@import "assets/scss/responsive";
@import "assets/scss/components/admin-sidebar.scss";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import '@ctrl/ngx-emoji-mart/picker';
@import 'ngx-toastr/toastr';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// body{
//   background: #333 !important;
// }

/* position */

.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}


/* toast styles */

.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #FFFFFF;
}

.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}


/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
}

.toast-container .toast:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}


/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */

.toast-info {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}


/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */

.toast-error {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}


/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */

.toast-success {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}


/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */

.toast-warning {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .toast,
.toast-container.toast-bottom-center .toast {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .toast,
.toast-container.toast-bottom-full-width .toast {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.toast {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51A351;
}

.toast-error {
    background-color: #BD362F;
}

.toast-info {
    background-color: #2F96B4;
}

.toast-warning {
    background-color: #F89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

// menu language dropdown
.menu-dropdown {
    &.lang-drop-menu {
        min-width: 119px !important;
        top: 0;
        position: relative;
        border-top: 2px solid #008bec;

        button {
            text-transform: capitalize;
        }
    }
}

.slideshow-container.slideshow-container-fs {
    position: fixed;
    left: 50px !important;
    top: 50px !important;
    right: 50px !important;
    height: auto !important;
    bottom: 50px !important;
    width: auto !important;
    background-color: #000 !important;
}


/* Responsive Design */

@media all and (max-width: 240px) {
    .toast-container .toast.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .toast.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .toast.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

.form-wrapper {
    .mat-form-field {
        display: block;
        margin-bottom: 30px;

        &.mat-form-field-should-float {
            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    font-size: 14px !important;
                }
            }
        }

        .mat-form-field-label-wrapper {
            font-size: 14px !important;
            padding-top: 25px !important;
            top: -.9em !important;

            .mat-form-field-label {
                font-size: 16px !important;
                color: #000 !important;
            }
        }

        &.mat-form-field-should-float,
        .mat-form-field-autofilled {
            .mat-form-field-label {
                transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
                -moz-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
                -webkit-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
                -ms-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
            }
        }
    }
}

.form-wrapper {
    .mat-checkbox-inner-container {
        height: 18px;
        width: 18px
    }

    .mat-form-field-infix {
        font-size: 15px;

        .text-danger {
            position: absolute;
            left: 0px;
            bottom: -18px;
            font-size: 12px;
        }
    }
}


/**Slide Image **/

strong,
b {
    font-weight: 600;
}

i {
    font-style: italic;
}

.warning-class {
    svg {
        fill: #B22222;
        font-size: 2rem;
    }
}

// Prefrences tabs start here
.prefrences-tabs {
    .mat-radio-checked .mat-radio-inner-circle {
        transform: scale(.6);
    }

    .mat-tab-body {
        li {
            width: 100%;
        }
    }

    .mat-tab-label {
        background: #ffffff;
        border-radius: 0.3rem 0.3rem 0 0;
        border-top: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        margin-right: 0.5rem;
        opacity: 1;
        min-width: 140px;

        &.mat-tab-label-active {
            background: #018bec;
            color: #ffffff;
        }
    }

    .mat-tab-header,
    .mat-tab-nav-bar {
        border-bottom: none;
    }

    .mat-ink-bar {
        display: none;
    }

    .special-request {
        label {
            display: none !important;
        }
    }

    .mat-checkbox-frame {
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.3);
        border-radius: 0;
    }

    .mat-radio-outer-circle {
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.3);
    }
}

.mat-dialog-container {
    padding: 0 !important;
}

.mat-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
    // overflow: hidden !important;
}

.mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 25px !important;
}

.mat-dialog-actions .btn-link {
    color: #a0a0a0;
}

.add-prefrences-dialog {
    .mat-form-field-infix {
        min-height: 37px;
        border: 1px solid #adadad;
    }

    .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
        right: 11px;
    }

    .mat-form-field-type-mat-native-select .mat-input-element {
        padding-left: 10px;
    }

    select.mat-input-element {
        top: -0.8em;
    }

    .mat-form-field {
        width: 100%;
    }

    .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: .7em 1em;
    }

    .title-none {
        label {
            display: none;
        }
    }

    .special-request label {
        display: none !important;
    }

    textarea {
        min-height: 120px;
        border: 1px solid #adadad;
        padding: 17px !important;
    }

    .add-prefreences-dialog-body {
        table {
            thead {
                background: #efefef;
                border-radius: 3px 3px 0 0;
            }

            .mat-form-field-appearance-legacy .mat-form-field-wrapper {
                margin-bottom: -8px;
                margin-top: 10px;
            }

            th.mat-header-cell,
            td.mat-cell {
                &:last-of-type {
                    text-align: right;
                }
            }
        }

        .mat-header-cell {
            color: rgba(0, 0, 0, 1);
            font-size: 13px;
        }
    }
}


.chat-footer .profile-image {
    top: 516px !important;
}

// booking table
.booking-table {
    box-shadow: 0 0 6px #0000001a;
}

.web-checkin {
    position: relative;
    top: 4px;

    .mat-checkbox-label {
        position: relative;
        top: 2px;
    }
}

.heading-fliter {
    background-color: #efefef;
    margin: 10px 0 15px;
    padding: 10px 10px 10px 25px;
}

.serach-top .mat-form-field {
    line-height: 1.2;
}

.make-booking {
    min-height: calc(100vh - 73px);
    height: calc(100% - 73px);
    padding-top: 70px;
}

.cursor-pointer {
    cursor: pointer;
}

.mb20 {
    margin-bottom: 20px;
}

.room-manage-page {
    .home-page {
        display: inherit !important;
    }
}

.mat-option-text .staffImgWrap {
    margin: 11px 15px 0px 16px;
    height: 28px;
}

.mat-select-panel .btn.btn-option {
    line-height: 42px;

    i.material-icons {
        position: relative;
        top: 11px;
    }
}

.main-item-table.dashboard-table {
    table {

        td,
        th {
            line-height: 30px;
        }
    }
}

.custom-container-message {
    max-width: 94%;
    margin-bottom: 190px;
}

.message-user-list {
    .mat-list-item {
        min-height: auto !important;

        .icon.food-color {
            overflow: hidden;
        }
    }
}

.chat-box-area {
    background: #F1F5F8;

    .chat-middle-area {
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    .chat-footer {
        background: #fff;

        .row {
            width: 100%;
            align-items: center;
            justify-content: center;
            margin: 0;
            flex-wrap: nowrap;

            .message-input {
                align-items: center;
                background: #F5F6F8;
                border-radius: 10px;
                padding: 10px 15px;
                border: 1px solid #D6DAE0;
                height: fit-content;

                button {
                    border: 0;
                    background: transparent;
                }

                textarea {
                    border: 0;
                    width: 100%;
                    vertical-align: middle;
                    line-height: 18px;
                    background: transparent
                }

                textarea::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}


.right-info.messaging-area .message-user-list .list-inner-section {
    border-bottom: 3px solid #d5dbde;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
}

.uploadAttachment .uload-image {
    top: 7px;
}

.innter-drop-content {
    display: flex !important;
    justify-content: center;
    align-items: center;

    i {
        color: #7F8FA4;
        font-size: 20px;
        margin-right: 5px;
    }

    span {
        font-size: 14px;
        color: #7F8FA4;
    }
}

// dashboard date filter
.date-info {
    input {
        border: 0;
        color: #1fa8ef;
    }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: #1fa8ef;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
    background: #1fa8ef17;
}

.custom-time {
    .custom-date {
        .mat-form-field {
            margin-bottom: 10px;
        }
    }

    .custom-time .time-input-outer {
        border-top: 0 !important;
    }
}

.mian-container-wrapper.manage-profile-section {
    .uploadedimg-wrapper {
        .upload-img-wrapper {
            width: 135px;
            height: 135px;
            background-image: none;
            border-radius: 340px;
            position: absolute;
            right: 0;
            bottom: -4px;
            left: -5px;
            border: 5px solid #d0d1dd;
            overflow: hidden;

            .profile-image {
                top: 0;
                border-radius: 0;
                width: 135px;
                height: 135px;
                overflow: hidden;
                float: right;

                img {
                    width: auto !important;
                    height: 100%;
                    max-width: inherit !important;
                }
            }

            .uload-image {
                left: inherit;
                top: inherit;
                color: #fff;
                right: 40px;
                bottom: 5px;
                background: #0998fd;
                border-radius: 50px;
                border-top: 4px solid #0998fd;

            }
        }
    }
}

.all-messages {
    .mat-tab-labels>div {
        width: 50% !important;
    }

}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';